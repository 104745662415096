import { DateComponentDTO } from '@services/DTO/DateComponentDTO'
import { TableCombinationDTO } from '@services/DTO/TableCombinationDTO'
import { TableDTO } from '@services/DTO/TableDTO'

export class AreaDTO {

    constructor(
        public id: string,
        public displayName: string,
        public description: string | null,
        public tableTurnaroundInterval: number,
        public automaticAcceptSchedule: DateComponentDTO[],
        public automaticAcceptPartySize: number | null,
        public displayOrder: number,
        public isActive: boolean,
        public shouldDeclareIfNotChosen: boolean,
        public tables: TableDTO[],
        public combinations: TableCombinationDTO[],
        public scheduleIds: string[],
        public dateCreated: Date,
        public dateUpdated: Date
    ) { }
}

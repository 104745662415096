export type PaymentGateway = 'stripe' | 'breathepay';

export class PaymentsAccountDTO {
    constructor(
        public id: string,
        public depositApplicationFeePercent: number,
        public gateway: PaymentGateway,
        public apiKey: string | null
    ) { }
}


<h4>
    {{ date | dateTime : venue.timeZone : DateTime.DATE_FULL }}
    @if (serviceDescription) {
        - {{ serviceDescription.name }}
        ({{ serviceDescription.start | dateTime : venue.timeZone : DateTime.TIME_24_SIMPLE }} -
        {{ serviceDescription.end | dateTime : venue.timeZone : DateTime.TIME_24_SIMPLE }})
    }
</h4>
<div>
    <table class="table mb-5 pb-5 bookings-print-out">
        <colgroup>
            <col>
            <col>
            <col>
            <col>
            <col *ngIf="venue.reasons.length > 0">
            <col>
            <col>
        </colgroup>
        <thead>
            <tr class="text-uppercase">
                <th scope="col">
                    Time In - Out
                </th>
                <th scope="col">
                    Name
                </th>
                <th scope="col">
                    Phone
                </th>
                <th scope="col">
                    Covers
                </th>
                <th
                    *ngIf="venue.reasons.length > 0 || venue.events.length > 0"
                    scope="col">
                    Reason
                </th>
                <th scope="col">
                    Notes
                </th>
                <th scope="col">
                    Table
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let entry of bookingsOnTables">
                <td>
                    {{entry.booking.start | dateTime : venue.timeZone : DateTime.TIME_24_SIMPLE }}
                    -
                    {{entry.booking.end | dateTime : venue.timeZone : DateTime.TIME_24_SIMPLE }}
                </td>
                <td>{{entry.booking.firstName}} {{entry.booking.lastName}}</td>
                <td>{{ entry.booking.phoneNumber | phoneNumber }}</td>
                <td>{{entry.booking.size}}</td>
                <td
                    *ngIf="venue.reasons.length > 0 || venue.events.length > 0"
                >
                    <span
                        *ngIf="entry.reason"
                    >
                        {{entry.reason.displayName}}
                    </span>
                    <span
                        *ngIf="entry.event"
                    >
                        {{entry.event.displayName}}
                    </span>
                </td>
                @if (printType === 'large-parties') {
                    <td>
                        @if (entry.booking.notes && entry.booking.merchantNotes) {
                            <div>
                                <div><strong>Customer:</strong> {{entry.booking.notes}}</div>
                                <hr class="my-1">
                                <div><strong>Staff:</strong> {{entry.booking.merchantNotes}}</div>
                            </div>
                        } @else if (entry.booking.notes) {
                            <div><strong>Customer:</strong> {{entry.booking.notes}}</div>
                        } @else if (entry.booking.merchantNotes) {
                            <div><strong>Staff:</strong> {{entry.booking.merchantNotes}}</div>
                        }
                    </td>
                } @else {
                    <td>
                        @if (entry.booking.notes && entry.booking.merchantNotes) {
                            <div>
                                <div><strong>Customer:</strong> {{entry.booking.notes | slice:0:90}}
                                    <span *ngIf="entry.booking.notes.length > 90">…</span>
                                </div>
                                <hr class="my-1">
                                <div><strong>Staff:</strong> {{entry.booking.merchantNotes | slice:0:90}}
                                    <span *ngIf="entry.booking.merchantNotes.length > 90">…</span>
                                </div>
                            </div>
                        } @else if (entry.booking.notes) {
                            <div><strong>Customer:</strong> {{entry.booking.notes | slice:0:180}}
                                <span *ngIf="entry.booking.notes.length > 180">…</span>
                            </div>
                        } @else if (entry.booking.merchantNotes) {
                            <div><strong>Staff:</strong> {{entry.booking.merchantNotes | slice:0:180}}
                                <span *ngIf="entry.booking.merchantNotes.length > 180">…</span>
                            </div>
                        }
                    </td>
                }
                <td>{{ entry.booking | tableName: entry.area: 'short' }}</td>
            </tr>
        </tbody>
    </table>
</div>

import { PartySizeDurationDTO } from '@services/DTO/PartySizeDurationDTO'
import { ReasonDateRangeFilterDTO } from '@services/DTO/ReasonDateRangeFilterDTO'
import { ReasonScheduleRuleDTO } from '@services/DTO/ReasonScheduleRuleDTO'
import { ChargeRangeDTO } from '@services/DTO/ChargeRangeDTO'

export class BookingReasonDTO {
    constructor(
        public id: string,
        public displayName: string,
        public displayOrder: number,
        public description: string | null,
        public contributesToKitchenVelocity: boolean,
        public schedule: ReasonScheduleRuleDTO[],
        public dateRangeFilters: ReasonDateRangeFilterDTO[],
        public areaBookingOrder: string[] | null,
        public bookingDurations: PartySizeDurationDTO[],
        public diningInformation: string | null,
        public minimumPartySize: number | null,
        public linkExclusive: boolean,
        public cancellationChargeRanges: ChargeRangeDTO[],
        public depositChargeRanges: ChargeRangeDTO[]
    ) { }
}

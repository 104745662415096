import { Address } from '@app/domain/Address'
import { AddressDTO } from '@services/DTO/AddressDTO'
import { Answer } from '@app/domain/Answer'
import { ChargeRange } from '@app/domain/ChargeRange'
import { ChargeRangeDTO } from '@services/DTO/ChargeRangeDTO'
import { AnswerDTO } from '@services/DTO/AnswerDTO'
import { Area } from '../domain/Area'
import { AreaDTO } from './DTO/AreaDTO'
import { AreaSchedule } from '@app/domain/AreaSchedule'
import { AreaScheduleDTO } from '@services/DTO/AreaScheduleDTO'
import { BillingDetails } from '@app/domain/BillingDetails'
import { BillingDetailsDTO } from '@services/DTO/BillingDetailsDTO'
import { Booking } from '@app/domain/Booking'
import { BookingAuditEntry } from '@app/domain/BookingAuditEntry'
import { BookingAuditEntryDTO } from '@services/DTO/BookingAuditEntryDTO'
import { BookingDTO } from '@services/DTO/BookingDTO'
import { BookingReason } from '@app/domain/BookingReason'
import { BookingReasonDTO } from '@services/DTO/BookingReasonDTO'
import { BookingReminder } from '@app/domain/BookingReminder'
import { BookingReminderDTO } from '@services/DTO/BookingReminderDTO'
import { BookingRequest } from '../features/reports/domain/BookingRequest'
import { BookingRequestDTO } from '../features/reports/services/DTO/BookingRequestDTO'
import { BookingSlot } from '../domain/BookingSlot'
import { BookingSlotDTO } from './DTO/BookingSlotDTO'
import { BookingSlotVelocityLimit, VenueVelocity } from '@app/domain/VenueVelocity'
import { BookingSlotVelocityLimitDTO, VenueVelocityDTO } from '@services/DTO/VenueVelocityDTO'
import { BookingSource } from '@app/domain/BookingSource'
import { BookingSourceDTO } from '@services/DTO/BookingSourceDTO'
import { BookingStatus, BookingStatusType } from '@app/domain/BookingStatus'
import { BookingStatusDTO, BookingStatusTypeDTO } from '@services/DTO/BookingStatusDTO'
import { BookingsFeedbackReportData } from '@app/features/reports/domain/BookingsFeedbackReportData'
import {
    BookingsFeedbackReportDataDTO,
} from '@app/features/reports/services/DTO/BookingsFeedbackReportDataDTO'
import { BookingsPerHourReportData } from '@app/features/reports/domain/BookingsPerHourReportData'
import {
    BookingsPerHourReportDataDTO,
} from '@app/features/reports/services/DTO/BookingsPerHourReportDataDTO'
import { BookingsReportData } from '@app/features/reports/domain/BookingsReportData'
import { BookingsReportDataDTO } from '@app/features/reports/services/DTO/BookingsReportDataDTO'
import { BookingsReportGraph } from '@app/features/reports/domain/BookingsReportGraph'
import { BookingsReportGraphDTO } from '@app/features/reports/services/DTO/BookingsReportGraphDTO'
import { Brand } from '@app/domain/Brand'
import { BrandDTO } from '@services/DTO/BrandDTO'
import { Business } from '../domain/Business'
import { BusinessDTO } from './DTO/BusinessDTO'
import { Cancellation } from '@app/domain/Cancellation'
import { CancellationCharge } from '@app/domain/CancellationCharge'
import { CancellationChargeDTO } from '@services/DTO/CancellationChargeDTO'
import { CancellationDTO } from '@services/DTO/CancellationDTO'
import {
    CommunicationPreferences,
    ExternalReviewSource,
} from '@app/domain/CommunicationPreferences'
import {
    CommunicationPreferencesDTO,
    ExternalReviewSourceDTO,
} from '@services/DTO/CommunicationPreferencesDTO'
import { Contact } from '@app/domain/Contact'
import { ContactDTO } from '@services/DTO/ContactDTO'
import { CreateUserRequest } from '@app/domain/CreateUserRequest'
import { CreateUserRequestDTO } from '@services/DTO/CreateUserRequestDTO'
import { CreateVenueRequest } from '@app/domain/CreateVenueRequest'
import { CreateVenueRequestDTO } from '@services/DTO/CreateVenueRequestDTO'
import { Customer } from '@app/domain/Customer'
import { CustomerDTO } from '@services/DTO/CustomerDTO'
import { CustomerListExportData } from '@app/features/reports/domain/CustomerListExportData'
import {
    CustomerListExportDataDTO,
} from '@app/features/reports/services/DTO/CustomerListExportDataDTO'
import { DateComponent } from '@app/domain/DateComponent'
import { DateComponentDTO } from '@services/DTO/DateComponentDTO'
import { DateTime } from 'luxon'
import { Deposit } from '@app/domain/Deposit'
import { DepositDTO } from '@services/DTO/DepositDTO'
import { DepositsReportData } from '@app/features/reports/domain/DepositsReportData'
import { DepositsReportDataDTO } from '@app/features/reports/services/DTO/DepositsReportDataDTO'
import { DepositsReportGraph } from '@app/features/reports/domain/DepositsReportGraph'
import { DepositsReportGraphDTO } from '@app/features/reports/services/DTO/DepositsReportGraphDTO'
import { DiaryNote } from '@app/domain/DiaryNote'
import { DiaryNoteDTO } from '@services/DTO/DiaryNoteDTO'
import { DiaryPreferences } from '@app/domain/DiaryPreferences'
import { DiaryPreferencesDTO } from '@services/DTO/DiaryPreferencesDTO'
import {
    EmailMarketingConfiguration,
    EmailMarketingStatus,
} from '@app/domain/integration/email-marketing/EmailMarketingConfiguration'
import {
    EmailMarketingConfigurationDTO,
    EmailMarketingStatusDTO,
} from '@services/DTO/email-marketing/EmailMarketingConfigurationDTO'
import { EmailMarketingList } from '@app/domain/integration/email-marketing/EmailMarketingList'
import {
    EmailMarketingListAssociationType,
    EmailMarketingListField,
} from '@app/domain/integration/email-marketing/EmailMarketingListField'
import {
    EmailMarketingListAssociationTypeDTO,
    EmailMarketingListFieldDTO,
} from '@services/DTO/email-marketing/EmailMarketingListFieldDTO'
import { EmailMarketingListDTO } from '@services/DTO/email-marketing/EmailMarketingListDTO'
import { EmailMarketingSource } from '@app/domain/integration/email-marketing/EmailMarketingSource'
import { EmailMarketingSourceDTO } from '@services/DTO/email-marketing/EmailMarketingSourceDTO'
import { EmailMarketingTag } from '@app/domain/integration/email-marketing/EmailMarketingTag'
import { EmailMarketingTagDTO } from '@services/DTO/email-marketing/EmailMarketingTagDTO'
import {
    Event,
    EventException,
    EventRecurrence,
    MonthlyRepetitionType,
} from '@app/domain/VenueEvent'
import {
    EventDTO,
    EventExceptionDTO,
    EventRecurrenceDTO,
    MonthlyRepetitionTypeDTO,
} from '@services/DTO/EventDTO'
import { Helper } from '@app/helpers/utilities/helper'
import { Injectable } from '@angular/core'
import { MoveBookingsRequestDTO } from '@services/DTO/MoveBookingsRequestDTO'
import { Organisation } from '../domain/Organisation'
import { OrganisationDTO } from './DTO/OrganisationDTO'
import { PartySizeDuration } from '@app/domain/PartySizeDuration'
import { PartySizeDurationDTO } from '@services/DTO/PartySizeDurationDTO'
import { PaymentsAccount } from '@app/domain/PaymentsAccount'
import { PaymentsAccountDTO } from '@services/DTO/PaymentsAccountDTO'
import { PaymentsAccountDetails } from '@app/domain/PaymentsAccountDetails'
import { PaymentsAccountDetailsDTO } from '@services/DTO/PaymentAccountDetailsDTO'
import { Period } from '@app/domain/Period'
import { PeriodDTO } from '@services/DTO/PeriodDTO'
import { Question, QuestionType } from '@app/domain/Question'
import { QuestionDTO, QuestionTypeDTO } from '@services/DTO/QuestionDTO'
import { ReasonDateRangeFilter } from '@app/domain/ReasonDateRangeFilter'
import { ReasonDateRangeFilterDTO } from '@services/DTO/ReasonDateRangeFilterDTO'
import { ReasonScheduleRule } from '@app/domain/ReasonScheduleRule'
import { ReasonScheduleRuleDTO } from '@services/DTO/ReasonScheduleRuleDTO'
import { Schedule } from '@app/domain/Schedule'
import { ScheduleDTO } from '@services/DTO/ScheduleDTO'
import { ScheduleException } from '@app/domain/ScheduleException'
import { ScheduleExceptionDTO } from '@services/DTO/ScheduleExceptionDTO'
import { ScheduleRule } from '@app/domain/ScheduleRule'
import { ScheduleRuleDTO } from '@services/DTO/ScheduleRuleDTO'
import { SenderId } from '../domain/SenderId'
import { SenderIdDTO } from './DTO/SenderIdDTO'
import { Service } from '@app/domain/Service'
import { ServiceDTO } from '@services/DTO/ServiceDTO'
import { Table } from '../domain/Table'
import { TableCombination } from '@app/domain/TableCombination'
import { TableCombinationDTO } from '@services/DTO/TableCombinationDTO'
import { TableDTO } from './DTO/TableDTO'
import { Time } from '@app/domain/Time'
import { User, UserRole } from '@app/domain/User'
import { UserDTO, UserRoleDTO } from '@services/DTO/UserDTO'
import { UserNotificationPreferences } from '@app/domain/UserNotificationPreferences'
import { UserNotificationPreferencesDTO } from '@services/DTO/UserNotificationPreferencesDTO'
import { Venue } from '@app/domain/Venue'
import { VenueClosure } from '@app/domain/VenueClosure'
import { VenueClosureDTO } from '@services/DTO/VenueClosureDTO'
import { VenueDTO } from '@services/DTO/VenueDTO'
import { VenueSchedule } from '@app/domain/VenueSchedule'
import { VenueScheduleDTO } from '@services/DTO/VenueScheduleDTO'
import { WidgetConfiguration } from '@app/domain/WidgetConfiguration'
import { WidgetConfigurationDTO } from '@services/DTO/WidgetConfigurationDTO'
import { environment } from '../../environments/environment'
import DineroFactory from 'dinero.js'
import { SKUDTO } from '@services/DTO/SKUDTO'
import { ProductLibrary } from '@app/domain/ProductLibrary'
import { ProductLibraryDTO } from '@services/DTO/ProductLibraryDTO'
import { Voucher } from '@app/domain/Voucher'
import { VoucherDTO } from '@services/DTO/VoucherDTO'
import { SKU } from '@app/domain/SKU'
import { GiftedVoucherDTO } from '@services/DTO/GiftedVoucherDTO'
import { GiftedVoucher } from '@app/domain/GiftedVoucher'
import { SaleDTO } from '@services/DTO/SaleDTO'
import { Sale } from '@app/domain/Sale'
import { PaymentDTO } from '@services/DTO/PaymentDTO'
import { Payment } from '@app/domain/Payment'
import { DeliveryDetailsDTO } from '@services/DTO/DeliveryDetailsDTO'
import { DeliveryDetails } from '@app/domain/DeliveryDetails'
import { OrderDTO } from '@services/DTO/OrderDTO'
import { Order } from '@app/domain/Order'
import { LineItemDTO } from '@services/DTO/LineItemDTO'
import { LineItem } from '@app/domain/LineItem'
import { GiftedVoucherAuditEntryDTO } from '@app/domain/GiftedVoucherAuditEntryDTO'
import { GiftedVoucherAuditEntry } from '@app/domain/GiftedVoucherAuditEntry'

@Injectable({
    providedIn: 'root',
})
export class DTOAdapter {

    constructor() { }

    adaptOrganisation(organisation: Organisation): OrganisationDTO {
        const businesses = organisation.businesses.map(business => this.adaptBusiness(business))
        return new OrganisationDTO(
            organisation.id,
            organisation.displayName,
            businesses,
            []
        )
    }

    adaptOrganisationDto(dto: OrganisationDTO): Organisation {
        const businesses = dto.businesses.map(dto => this.adaptBusinessDto(dto))
        const schedules = dto.schedules.map(dto => this.adaptScheduleDto(dto))
        return new Organisation(
            dto.id,
            dto.displayName,
            businesses,
            schedules
        )
    }

    adaptBusiness(business: Business): BusinessDTO {
        const venues = business.venues.map(venue => this.adaptVenue(venue))
        const address = this.adaptAddress(business.address)
        const paymentsAccount = business.paymentsAccount
        return new BusinessDTO(
            business.id,
            business.displayName,
            business.phoneNumber,
            address,
            paymentsAccount ? this.adaptPaymentsAccount(paymentsAccount) : null,
            venues
        )
    }

    adaptBusinessDto(dto: BusinessDTO): Business {
        const venues = dto.venues.map(dto => this.adaptVenueDto(dto))
        const paymentsAccount = dto.paymentsAccount
        return new Business(
            dto.id,
            dto.displayName,
            dto.phoneNumber,
            this.adaptAddressDto(dto.address),
            paymentsAccount ? this.adaptPaymentsAccountDto(paymentsAccount) : null,
            venues
        )
    }

    adaptVenue(venue: Venue): VenueDTO {
        const areas = venue.areas.map(area => this.adaptArea(area))
        let manualAcceptanceUntilDate = null
        if (venue.manualAcceptanceUntilDate !== null) {
            manualAcceptanceUntilDate = Helper.makeLocalISOFormattedDateString(
                venue.manualAcceptanceUntilDate
            )
        }
        const reasons = venue.reasons.map(reason => this.adaptBookingReason(reason))
        const questions = venue.questions.map(question => this.adaptQuestion(question))
        const events = venue.events.map(event => this.adaptEvent(event))
        return new VenueDTO(
            venue.id,
            venue.displayName,
            venue.phoneNumber,
            this.adaptAddress(venue.address),
            venue.bookingInterval,
            venue.minLargePartySize,
            venue.largePartyMessage,
            venue.noBookingSlotAvailableMessage,
            venue.shortPreBookingWindowMessage,
            venue.dogFriendlyNoAvailabilityMessage,
            venue.timeZone,
            venue.cancellationChargeRanges.map(range => new ChargeRangeDTO(
                range.minPartySize,
                range.maxPartySize,
                range.unitAmount,
                range.isPerCover
            )),
            venue.depositRanges.map(range => new ChargeRangeDTO(
                range.minPartySize,
                range.maxPartySize,
                range.unitAmount,
                range.isPerCover
            )),
            venue.cancellationChargeCutOffHours,
            venue.cancellationChargeAutomatically,
            this.adaptCommunicationPreferences(venue.communicationPreferences),
            venue.depositRefundCutOffDays,
            venue.depositRefundPolicy ? venue.depositRefundPolicy : null,
            this.adaptDiaryPreferences(venue.diaryPreferences),
            this.adaptVenueVelocity(venue.velocity),
            this.adaptWidgetConfiguration(venue.widgetConfiguration),
            areas,
            [...venue.areaBookingOrder],
            manualAcceptanceUntilDate,
            this.adaptBrand(venue.brand),
            venue.bookingPolicy,
            reasons,
            questions,
            events
        )
    }

    adaptVenueDto(dto: VenueDTO): Venue {
        const areas = dto.areas.map(dto => this.adaptAreaDto(dto))
        const reasons = dto.reasons.map(dto => this.adaptBookingReasonDto(dto))
        const questions = dto.questions.map(dto => this.adaptQuestionDto(dto))
        const events = dto.events.map(dto => this.adaptEventDto(dto))
        return new Venue(
            dto.id,
            dto.displayName,
            dto.phoneNumber,
            this.adaptAddressDto(dto.address),
            dto.bookingInterval,
            dto.minLargePartySize,
            dto.largePartyMessage,
            dto.noBookingSlotAvailableMessage,
            dto.shortPreBookingWindowMessage,
            dto.dogFriendlyNoAvailabilityMessage,
            dto.timeZone,
            dto.cancellationChargeRanges.map(dto => new ChargeRange(
                dto.minPartySize,
                dto.maxPartySize,
                dto.unitAmount,
                dto.isPerCover
            )),
            dto.depositChargeRanges.map(dto => new ChargeRange(
                dto.minPartySize,
                dto.maxPartySize,
                dto.unitAmount,
                dto.isPerCover
            )),
            dto.cancellationChargeCutOffHours,
            dto.cancellationChargeAutomatically,
            this.adaptCommunicationPreferencesDto(dto.communicationPreferences),
            dto.depositRefundCutOffDays,
            dto.depositRefundPolicy ? dto.depositRefundPolicy : null,
            this.adaptDiaryPreferencesDto(dto.diaryPreferences),
            this.adaptVenueVelocityDto(dto.velocity),
            this.adaptWidgetConfigurationDto(dto.widgetConfiguration),
            areas,
            [...dto.areaBookingOrder],
            dto.manualAcceptanceUntilDate ? new Date(dto.manualAcceptanceUntilDate) : null,
            this.adaptBrandDto(dto.brand),
            dto.bookingPolicy,
            reasons,
            questions,
            events
        )
    }

    adaptCommunicationPreferences(pref: CommunicationPreferences): CommunicationPreferencesDTO {
        return new CommunicationPreferencesDTO(
            pref.sendReminderHours,
            pref.sendFeedbackRequest,
            pref.sendFeedbackRequestMinutes,
            this.adaptExternalReviewSource(pref.externalReviewSource),
            pref.externalReviewUrl,
            pref.externalReviewCount,
            pref.externalReviewDescription,
            pref.emailAddress,
            pref.replyToEmailAddress,
            pref.feedbackEmailRecipients,
            pref.sendRequestEmail,
            pref.sendConfirmationEmail,
            pref.sendCancellationEmail,
            pref.sendModificationEmail,
            pref.sendNoShowEmail,
            pref.sendEmailPerReviewCount,
            pref.sendFeedbackDailySummaryEmail,
            pref.sendUpcomingEmail,
            pref.sendNotificationWithNoteOnly,
            pref.sendNotificationMinimumPartySizeOnly,
            pref.diningInformation
        )
    }

    adaptDiaryPreferences(pref: DiaryPreferences): DiaryPreferencesDTO {
        return new DiaryPreferencesDTO(
            pref.confirmAddBooking,
            pref.merchantBookingsRequireChargeByDefault,
            pref.pendingPaymentExpiryMinutes
        )
    }

    adaptExternalReviewSource(source: ExternalReviewSource | null): ExternalReviewSourceDTO | null {
        if (source === null) { return null }
        switch (source) {
        case ExternalReviewSource.Google:
            return ExternalReviewSourceDTO.Google
        case ExternalReviewSource.TripAdvisor:
            return ExternalReviewSourceDTO.TripAdvisor
        }
    }

    adaptCommunicationPreferencesDto(dto: CommunicationPreferencesDTO): CommunicationPreferences {
        return new CommunicationPreferences(
            dto.sendReminderHours,
            dto.sendFeedbackRequest,
            dto.sendFeedbackRequestMinutes,
            this.adaptExternalReviewSourceDto(dto.externalReviewSource),
            dto.externalReviewUrl,
            dto.externalReviewCount,
            dto.externalReviewDescription,
            dto.emailAddress,
            dto.replyToEmailAddress,
            dto.feedbackEmailRecipients,
            dto.sendRequestEmail,
            dto.sendConfirmationEmail,
            dto.sendCancellationEmail,
            dto.sendModificationEmail,
            dto.sendNoShowEmail,
            dto.sendEmailPerReviewCount,
            dto.sendFeedbackDailySummaryEmail,
            dto.sendUpcomingEmail,
            dto.sendNotificationWithNoteOnly,
            dto.sendNotificationMinimumPartySizeOnly,
            dto.diningInformation
        )
    }

    adaptDiaryPreferencesDto(dto: DiaryPreferencesDTO): DiaryPreferences {
        return new DiaryPreferences(
            dto.confirmAddBooking,
            dto.merchantBookingsRequireChargeByDefault,
            dto.pendingPaymentExpiryMinutes
        )
    }

    adaptExternalReviewSourceDto(source: ExternalReviewSourceDTO | null):
        ExternalReviewSource | null {
        if (source === null) { return null }
        switch (source) {
        case ExternalReviewSourceDTO.Google:
            return ExternalReviewSource.Google
        case ExternalReviewSourceDTO.TripAdvisor:
            return ExternalReviewSource.TripAdvisor
        }
    }

    adaptVenueVelocity(velocity: VenueVelocity): VenueVelocityDTO {
        const bookingSlotBookingLimits = velocity.bookingSlotBookingLimits.map(limit => {
            return this.adaptBookingSlotVelocityLimit(limit)
        })
        const bookingSlotCoverLimits = velocity.bookingSlotCoverLimits.map(limit => {
            return this.adaptBookingSlotVelocityLimit(limit)
        })
        return new VenueVelocityDTO(
            velocity.bookingLimit,
            velocity.coverLimit,
            bookingSlotBookingLimits,
            bookingSlotCoverLimits
        )
    }

    adaptVenueVelocityDto(dto: VenueVelocityDTO): VenueVelocity {
        const bookingSlotBookingLimits = dto.bookingSlotBookingLimits.map(dto => {
            return this.adaptBookingSlotVelocityLimitDto(dto)
        })
        const bookingSlotCoverLimits = dto.bookingSlotCoverLimits.map(dto => {
            return this.adaptBookingSlotVelocityLimitDto(dto)
        })
        return new VenueVelocity(
            dto.bookingLimit,
            dto.coverLimit,
            bookingSlotBookingLimits,
            bookingSlotCoverLimits
        )
    }

    adaptBookingSlotVelocityLimit(limit: BookingSlotVelocityLimit): BookingSlotVelocityLimitDTO {
        return new BookingSlotVelocityLimitDTO(
            limit.dayOfWeek,
            limit.time.toString(),
            limit.limit
        )
    }

    adaptBookingSlotVelocityLimitDto(dto: BookingSlotVelocityLimitDTO): BookingSlotVelocityLimit {
        return new BookingSlotVelocityLimit(
            dto.dayOfWeek,
            new Time(dto.time, null, null),
            dto.limit
        )
    }

    adaptWidgetConfiguration(widgetConfiguration: WidgetConfiguration): WidgetConfigurationDTO {
        return new WidgetConfigurationDTO(
            widgetConfiguration.phoneNumberRequired,
            widgetConfiguration.reserveWithGoogleEnabled,
            widgetConfiguration.reserveWithGoogleReasonId ?? null,
            widgetConfiguration.primaryColour,
            widgetConfiguration.pinnedEventId ?? null
        )
    }

    adaptWidgetConfigurationDto(dto: WidgetConfigurationDTO): WidgetConfiguration {
        return new WidgetConfiguration(
            dto.phoneNumberRequired,
            dto.reserveWithGoogleEnabled,
            dto.reserveWithGoogleReasonId ?? null,
            dto.primaryColour,
            dto.pinnedEventId ?? null
        )
    }

    adaptBrand(brand: Brand): BrandDTO {
        return new BrandDTO(
            brand.brandColour,
            brand.bannerImageId,
            brand.facebookUrl,
            brand.instagramUrl,
            brand.twitterUrl,
            brand.websiteUrl,
            brand.linkedinUrl
        )
    }

    adaptBrandDto(dto: BrandDTO): Brand {
        return new Brand(
            dto.brandColour,
            dto.bannerImageId,
            dto.facebookUrl,
            dto.instagramUrl,
            dto.twitterUrl,
            dto.websiteUrl,
            dto.linkedinUrl
        )
    }

    adaptQuestion(question: Question): QuestionDTO {
        return new QuestionDTO(
            question.id,
            question.text,
            question.required,
            this.adaptQuestionType(question.type),
            [...question.options],
            [...question.reasonIds]
        )
    }

    adaptQuestionDto(dto: QuestionDTO): Question {
        return new Question(
            dto.id,
            dto.text,
            dto.required,
            this.adaptQuestionTypeDto(dto.type),
            [...dto.options],
            [...dto.reasonIds]
        )
    }

    adaptQuestionType(questionType: QuestionType): QuestionTypeDTO {
        switch (questionType) {
        case QuestionType.Checkbox:
            return QuestionTypeDTO.Checkbox
        case QuestionType.Dropdown:
            return QuestionTypeDTO.Dropdown
        case QuestionType.AcknowledgeEndTime:
            return QuestionTypeDTO.AcknowledgeEndTime
        case QuestionType.DOB:
            return QuestionTypeDTO.DOB
        case QuestionType.ReasonsForVisit:
            return QuestionTypeDTO.ReasonsForVisit
        }
    }

    adaptQuestionTypeDto(dto: QuestionTypeDTO): QuestionType {
        switch (dto) {
        case QuestionTypeDTO.Checkbox:
            return QuestionType.Checkbox
        case QuestionTypeDTO.Dropdown:
            return QuestionType.Dropdown
        case QuestionTypeDTO.AcknowledgeEndTime:
            return QuestionType.AcknowledgeEndTime
        case QuestionTypeDTO.DOB:
            return QuestionType.DOB
        case QuestionTypeDTO.ReasonsForVisit:
            return QuestionType.ReasonsForVisit
        }
    }

    adaptAnswer(answer: Answer): AnswerDTO {
        return new AnswerDTO(
            answer.question.id,
            answer.question.text,
            answer.question.required,
            this.adaptQuestionType(answer.question.type),
            answer.answer
        )
    }

    adaptAnswerDto(dto: AnswerDTO): Answer {
        const question = new Question(
            dto.questionId,
            dto.question,
            dto.required,
            this.adaptQuestionTypeDto(dto.type),
            [],
            []
        )
        return new Answer(
            question,
            dto.answer
        )
    }

    adaptEvent(event: Event): EventDTO {
        const recurrence = this.adaptEventRecurrence(event.recurrence)
        return new EventDTO(
            event.id,
            event.displayName,
            event.displayOrder,
            event.description,
            event.bookingDurations.map(duration => this.adaptPartySizeDuration(duration)),
            event.imageId,
            event.cancellationChargeAmount,
            event.cancellationChargePartyAmount,
            event.cancellationChargeCutOffHours,
            event.depositAmount,
            event.depositTableCapacityAmount,
            event.depositRefundCutOffDays,
            event.diningInformation,
            event.isExclusive,
            event.notReservableOnline,
            event.notReservableOnlineMessage,
            event.forceSendMerchantEmail,
            event.contributesToKitchenVelocity,
            event.preBookingWindowMinutes,
            event.preBookingModificationWindowMinutes,
            recurrence,
            [...event.tableIds],
            [...event.questionIds],
            event.partySizeRestriction ? [...event.partySizeRestriction] : null,
            event.dailyBookingLimit,
            event.partySizeRestrictedMessage
        )
    }

    adaptEventDto(dto: EventDTO): Event {
        const recurrence = this.adaptEventRecurrenceDto(dto.recurrence)
        return new Event(
            dto.id,
            dto.displayName,
            dto.displayOrder,
            dto.description,
            dto.bookingDurations.map(dto => this.adaptPartySizeDurationDto(dto)),
            dto.imageId,
            dto.cancellationChargeAmount,
            dto.cancellationChargePartyAmount,
            dto.cancellationChargeCutOffHours,
            dto.depositAmount,
            dto.depositTableCapacityAmount,
            dto.depositRefundCutOffDays,
            dto.diningInformation,
            dto.isExclusive,
            dto.notReservableOnline,
            dto.notReservableOnlineMessage,
            dto.forceSendMerchantEmail,
            dto.contributesToKitchenVelocity,
            dto.preBookingWindowMinutes,
            dto.preBookingModificationWindowMinutes,
            recurrence,
            [...dto.tableIds],
            [...dto.questionIds],
            dto.partySizeRestriction ? [...dto.partySizeRestriction] : null,
            dto.dailyBookingLimit,
            dto.partySizeRestrictedMessage
        )
    }

    adaptEventRecurrence(recurrence: EventRecurrence): EventRecurrenceDTO {
        const exceptions = recurrence.exceptions.map(exception => {
            return this.adaptEventException(exception)
        })
        return new EventRecurrenceDTO(
            recurrence.startDates.map(date => this.adaptToLocalDate(date)),
            recurrence.dateComponent,
            recurrence.weeklyRepeatsOnDaysOfWeek,
            recurrence.monthlyRepetitionType !== null
                ? this.adaptMonthlyRepetitionType(recurrence.monthlyRepetitionType)
                : null,
            exceptions,
            recurrence.endDate
                ? this.adaptToLocalDate(recurrence.endDate)
                : null,
            recurrence.startTime?.toString() ?? null,
            recurrence.endTime?.toString() ?? null
        )
    }

    adaptEventRecurrenceDto(dto: EventRecurrenceDTO): EventRecurrence {
        const exceptions = dto.exceptions.map(dto => {
            return this.adaptEventExceptionDto(dto)
        })
        return new EventRecurrence(
            dto.startDates.map(date => DateTime.fromISO(date, { zone: environment.assumedVenueTimeZone }).toJSDate()),
            dto.dateComponent,
            dto.weeklyRepeatsOnDaysOfWeek,
            dto.monthlyRepetitionType !== null
                ? this.adaptMonthlyRepetitionTypeDto(dto.monthlyRepetitionType)
                : null,
            exceptions,
            dto.endDate
                ? new Date(dto.endDate)
                : null,
            dto.startTime ? new Time(dto.startTime, null, null) : null,
            dto.endTime ? new Time(dto.endTime, null, null) : null
        )
    }

    adaptEventException(exception: EventException): EventExceptionDTO {
        return new EventExceptionDTO(
            exception.id,
            this.adaptToLocalDate(exception.date)
        )
    }

    adaptEventExceptionDto(dto: EventExceptionDTO): EventException {
        return new EventException(
            dto.id,
            new Date(dto.date)
        )
    }

    adaptMonthlyRepetitionType(
        monthlyRepetitionType: MonthlyRepetitionType
    ): MonthlyRepetitionTypeDTO {
        switch (monthlyRepetitionType) {
        case MonthlyRepetitionType.DayOfMonth:
            return MonthlyRepetitionTypeDTO.DAY_OF_MONTH
        case MonthlyRepetitionType.DayOfWeek:
            return MonthlyRepetitionTypeDTO.DAY_OF_WEEK
        }
    }

    adaptMonthlyRepetitionTypeDto(dto: MonthlyRepetitionTypeDTO): MonthlyRepetitionType {
        switch (dto) {
        case MonthlyRepetitionTypeDTO.DAY_OF_MONTH:
            return MonthlyRepetitionType.DayOfMonth
        case MonthlyRepetitionTypeDTO.DAY_OF_WEEK:
            return MonthlyRepetitionType.DayOfWeek
        }
    }

    adaptArea(area: Area): AreaDTO {
        const automaticAcceptSchedule = area.automaticAcceptSchedule
            .map(dateComponent => this.adaptDateComponent(dateComponent))
        const tables = area.tables.map(area => this.adaptTable(area))
        const combinations = area.combinations.map(combination => {
            return this.adaptTableCombination(combination)
        })
        return new AreaDTO(
            area.id,
            area.displayName,
            area.description,
            area.tableTurnaroundInterval,
            automaticAcceptSchedule,
            area.automaticAcceptPartySize,
            area.displayOrder,
            area.isActive,
            area.shouldDeclareIfNotChosen,
            tables,
            combinations,
            [...area.scheduleIds],
            area.dateCreated,
            area.dateUpdated
        )
    }

    adaptAreaDto(dto: AreaDTO): Area {
        const automaticAcceptSchedule = dto.automaticAcceptSchedule
            .map(dto => this.adaptDateComponentDto(dto))
        const tables = dto.tables.map(dto => this.adaptTableDto(dto))
        const combinations = dto.combinations.map(dto => this.adaptTableCombinationDto(dto))
        return new Area(
            dto.id,
            dto.displayName,
            dto.description,
            dto.tableTurnaroundInterval,
            automaticAcceptSchedule,
            dto.automaticAcceptPartySize,
            dto.displayOrder,
            dto.isActive,
            dto.shouldDeclareIfNotChosen,
            tables,
            combinations,
            [...dto.scheduleIds],
            dto.dateCreated,
            dto.dateUpdated
        )
    }

    adaptTableCombination(combination: TableCombination): TableCombinationDTO {
        return new TableCombinationDTO(
            combination.id,
            combination.tableIds,
            combination.minimumSeats,
            combination.maximumSeats,
            combination.bookingOrder,
            combination.independentReservable
        )
    }

    adaptTableCombinationDto(dto: TableCombinationDTO): TableCombination {
        return new TableCombination(
            dto.id,
            dto.tableIds,
            dto.minimumSeats,
            dto.maximumSeats,
            dto.bookingOrder,
            dto.independentReservable
        )
    }

    adaptPartySizeDuration(partySizeDuration: PartySizeDuration): PartySizeDurationDTO {
        return new PartySizeDurationDTO(
            partySizeDuration.partySize,
            partySizeDuration.duration
        )
    }

    adaptPartySizeDurationDto(dto: PartySizeDurationDTO): PartySizeDuration {
        return new PartySizeDuration(
            dto.partySize,
            dto.duration
        )
    }

    adaptCreateVenueRequest(request: CreateVenueRequest): CreateVenueRequestDTO {
        return new CreateVenueRequestDTO(
            request.id,
            request.displayName,
            request.phoneNumber,
            this.adaptAddress(request.address),
            request.maxPartySize,
            request.timeZone,
            request.areaId,
            request.areaDisplayName,
            request.defaultBookingDuration,
            request.bookingInterval,
            request.tableTurnaroundInterval,
            request.scheduleId
        )
    }

    adaptTable(table: Table): TableDTO {
        return new TableDTO(
            table.id,
            table.displayName,
            table.reservable,
            table.minimumSeats,
            table.maximumSeats,
            table.bookingOrder,
            table.displayOrder,
            table.floorCoordinates.x,
            table.floorCoordinates.y,
            table.dimensions.width,
            table.dimensions.height,
            [...table.reasonIds],
            table.wheelchairAccessible,
            table.dogFriendly,
            table.dateCreated.toISOString(),
            table.dateUpdated.toISOString()
        )
    }

    adaptTableDto(dto: TableDTO): Table {
        return new Table(
            dto.id,
            dto.displayName,
            dto.reservable,
            dto.minimumSeats,
            dto.maximumSeats,
            dto.bookingOrder,
            dto.displayOrder,
            {
                x: dto.floorPlanX,
                y: dto.floorPlanY,
            },
            {
                width: dto.floorPlanWidth,
                height: dto.floorPlanHeight,
            },
            [...dto.reasonIds],
            dto.wheelchairAccessible,
            dto.dogFriendly,
            new Date(dto.dateCreated),
            new Date(dto.dateUpdated)
        )
    }

    adaptBookingReason(bookingReason: BookingReason): BookingReasonDTO {
        return new BookingReasonDTO(
            bookingReason.id,
            bookingReason.displayName,
            bookingReason.displayOrder,
            bookingReason.description,
            bookingReason.contributesToKitchenVelocity,
            bookingReason.schedule
                .map(reasonScheduleRule => this.adaptReasonScheduleRule(reasonScheduleRule)),
            bookingReason.dateRangeFilters
                .map(filter => this.adaptReasonDateRangeFilter(filter)),
            bookingReason.areaBookingOrder ? [...bookingReason.areaBookingOrder] : null,
            bookingReason.bookingDurations.map(duration => this.adaptPartySizeDuration(duration)),
            bookingReason.diningInformation,
            bookingReason.minimumPartySize,
            bookingReason.linkExclusive,
            bookingReason.cancellationChargeRanges.map(range => new ChargeRangeDTO(
                range.minPartySize,
                range.maxPartySize,
                range.unitAmount,
                range.isPerCover
            )),
            bookingReason.depositChargeRanges.map(range => new ChargeRangeDTO(
                range.minPartySize,
                range.maxPartySize,
                range.unitAmount,
                range.isPerCover
            ))
        )
    }

    adaptBookingReasonDto(dto: BookingReasonDTO): BookingReason {
        return new BookingReason(
            dto.id,
            dto.displayName,
            dto.displayOrder,
            dto.description,
            dto.contributesToKitchenVelocity,
            dto.schedule.map(dto => this.adaptReasonScheduleRuleDto(dto)),
            dto.dateRangeFilters.map(dto => this.adaptReasonDateRangeFilterDto(dto)),
            dto.areaBookingOrder ? [...dto.areaBookingOrder] : null,
            dto.bookingDurations.map(dto => this.adaptPartySizeDurationDto(dto)),
            dto.diningInformation,
            dto.minimumPartySize,
            dto.linkExclusive,
            dto.cancellationChargeRanges.map(dto => new ChargeRange(
                dto.minPartySize,
                dto.maxPartySize,
                dto.unitAmount,
                dto.isPerCover
            )),
            dto.depositChargeRanges.map(dto => new ChargeRange(
                dto.minPartySize,
                dto.maxPartySize,
                dto.unitAmount,
                dto.isPerCover
            ))
        )
    }

    adaptReasonScheduleRule(reasonScheduleRule: ReasonScheduleRule): ReasonScheduleRuleDTO {
        return new ReasonScheduleRuleDTO(
            reasonScheduleRule.id,
            [...reasonScheduleRule.daysOfWeek],
            reasonScheduleRule.period ? this.adaptPeriod(reasonScheduleRule.period) : null,
            reasonScheduleRule.bookingDurations.map(duration => this.adaptPartySizeDuration(duration))
        )
    }

    adaptReasonScheduleRuleDto(dto: ReasonScheduleRuleDTO): ReasonScheduleRule {
        return new ReasonScheduleRule(
            dto.id,
            [...dto.daysOfWeek],
            dto.period ? this.adaptPeriodDto(dto.period) : null,
            dto.bookingDurations.map(dto => this.adaptPartySizeDurationDto(dto))
        )
    }

    adaptReasonDateRangeFilter(filter: ReasonDateRangeFilter): ReasonDateRangeFilterDTO {
        return new ReasonDateRangeFilterDTO(
            filter.id,
            this.adaptToLocalDateTime(filter.startDate),
            this.adaptToLocalDateTime(filter.endDate)
        )
    }

    adaptReasonDateRangeFilterDto(dto: ReasonDateRangeFilterDTO): ReasonDateRangeFilter {
        return new ReasonDateRangeFilter(
            dto.id,
            new Date(dto.startDate),
            new Date(dto.endDate)
        )
    }

    adaptAddress(address: Address): AddressDTO {
        return new AddressDTO(
            address.addressLineOne,
            address.addressLineTwo,
            address.city,
            address.county,
            address.postCode,
            address.country
        )
    }

    adaptAddressDto(dto: AddressDTO): Address {
        return new Address(
            dto.addressLineOne,
            dto.addressLineTwo,
            dto.city,
            dto.county,
            dto.postCode,
            dto.country
        )
    }

    adaptBooking(booking: Booking): BookingDTO {
        const statusEvents = booking.statusEvents.map(status => {
            return this.adaptBookingStatus(status)
        })
        const answers = booking.answers.map(answer => {
            return this.adaptAnswer(answer)
        })
        return new BookingDTO(
            booking.id,
            booking.organisationId,
            booking.tableIds,
            this.adaptContact(booking.contact),
            booking.customer ? this.adaptCustomer(booking.customer) : null,
            booking.size,
            this.adaptToLocalDateTime(booking.start),
            booking.duration,
            booking.notes,
            booking.merchantNotes,
            booking.agreedToMarketing,
            booking.agreedToTerms,
            booking.paymentCustomerId,
            this.adaptBookingSource(booking.source),
            booking.reminders.map(reminder => this.adaptBookingReminder(reminder)),
            statusEvents,
            booking.feedbackRequests,
            booking.feedback,
            booking.reasonId,
            booking.eventId,
            answers,
            booking.cancellation ? this.adaptCancellation(booking.cancellation) : null,
            booking.deposit ? this.adaptDeposit(booking.deposit) : null,
            booking.sendFeedbackRequest,
            booking.requiresWheelchairAccess,
            booking.requiresDogFriendly,
            booking.lockedToTables,
            booking.dateVoided?.toISOString() ?? null
        )
    }

    adaptBookingDto(dto: BookingDTO): Booking {
        const statusEvents = dto.statusEvents.map(dto => {
            return this.adaptBookingStatusDto(dto)
        })
        const answers = dto.answers.map(dto => {
            return this.adaptAnswerDto(dto)
        })
        const start = DateTime.fromISO(dto.start, { zone: environment.assumedVenueTimeZone })
            .toJSDate()
        return new Booking(
            dto.id,
            dto.organisationId,
            dto.tableIds,
            this.adaptContactDto(dto.contact),
            dto.customer ? this.adaptCustomerDto(dto.customer) : null,
            dto.size,
            start,
            dto.durationMinutes,
            dto.notes,
            dto.merchantNotes,
            dto.agreedToMarketing,
            dto.agreedToTerms,
            dto.paymentCustomerId,
            this.adaptBookingSourceDto(dto.source),
            dto.reminders.map(dto => this.adaptBookingReminderDto(dto)),
            statusEvents,
            dto.feedbackRequests,
            dto.feedback,
            dto.reasonId,
            dto.eventId,
            answers,
            dto.cancellation ? this.adaptCancellationDto(dto.cancellation) : null,
            dto.deposit ? this.adaptDepositDto(dto.deposit) : null,
            dto.sendFeedbackRequest,
            dto.requiresWheelchairAccess,
            dto.requiresDogFriendly,
            dto.lockedToTables,
            dto.dateVoided ? new Date(dto.dateVoided) : null
        )
    }

    adaptBookingStatus(status: BookingStatus): BookingStatusDTO {
        return new BookingStatusDTO(
            status.id,
            Object.fromEntries(status.metadata),
            status.dateTime.toISOString(),
            this.adaptBookingStatusType(status.type)
        )
    }

    adaptBookingStatusDto(dto: BookingStatusDTO): BookingStatus {
        return new BookingStatus(
            dto.id,
            new Map(Object.entries(dto.metadata)),
            new Date(dto.dateTime),
            this.adaptBookingStatusTypeDto(dto.type)
        )
    }

    adaptBookingStatusType(type: BookingStatusType): BookingStatusTypeDTO {
        switch (type) {
        case BookingStatusType.Requested:
            return BookingStatusTypeDTO.Requested
        case BookingStatusType.PendingPayment:
            return BookingStatusTypeDTO.PendingPayment
        case BookingStatusType.Booked:
            return BookingStatusTypeDTO.Booked
        case BookingStatusType.Cancelled:
            return BookingStatusTypeDTO.Cancelled
        case BookingStatusType.NoShow:
            return BookingStatusTypeDTO.NoShow
        case BookingStatusType.Waiting:
            return BookingStatusTypeDTO.Waiting
        case BookingStatusType.PartiallySeated:
            return BookingStatusTypeDTO.PartiallySeated
        case BookingStatusType.Seated:
            return BookingStatusTypeDTO.Seated
        case BookingStatusType.Finished:
            return BookingStatusTypeDTO.Finished
        case BookingStatusType.Rejected:
            return BookingStatusTypeDTO.Rejected
        }
    }

    adaptBookingStatusTypeDto(dto: BookingStatusTypeDTO): BookingStatusType {
        switch (dto) {
        case BookingStatusTypeDTO.Requested:
            return BookingStatusType.Requested
        case BookingStatusTypeDTO.PendingPayment:
            return BookingStatusType.PendingPayment
        case BookingStatusTypeDTO.Booked:
            return BookingStatusType.Booked
        case BookingStatusTypeDTO.Cancelled:
            return BookingStatusType.Cancelled
        case BookingStatusTypeDTO.NoShow:
            return BookingStatusType.NoShow
        case BookingStatusTypeDTO.Waiting:
            return BookingStatusType.Waiting
        case BookingStatusTypeDTO.PartiallySeated:
            return BookingStatusType.PartiallySeated
        case BookingStatusTypeDTO.Seated:
            return BookingStatusType.Seated
        case BookingStatusTypeDTO.Finished:
            return BookingStatusType.Finished
        case BookingStatusTypeDTO.Rejected:
            return BookingStatusType.Rejected
        }
    }

    adaptBookingSource(source: BookingSource): BookingSourceDTO {
        switch (source) {
        case BookingSource.Customer:
            return BookingSourceDTO.Customer
        case BookingSource.Google:
            return BookingSourceDTO.Google
        case BookingSource.Merchant:
            return BookingSourceDTO.Merchant
        }
    }

    adaptBookingSourceDto(dto: BookingSourceDTO): BookingSource {
        switch (dto) {
        case BookingSourceDTO.Customer:
            return BookingSource.Customer
        case BookingSourceDTO.Google:
            return BookingSource.Google
        case BookingSourceDTO.Merchant:
            return BookingSource.Merchant
        }
    }

    adaptContact(contact: Contact): ContactDTO {
        return new ContactDTO(
            contact.firstName,
            contact.lastName,
            contact.emailAddress,
            contact.phoneNumber
        )
    }

    adaptContactDto(dto: ContactDTO): Contact {
        return new Contact(
            dto.firstName,
            dto.lastName,
            dto.emailAddress,
            dto.phoneNumber
        )
    }

    adaptBookingReminder(reminder: BookingReminder): BookingReminderDTO {
        return new BookingReminderDTO(
            reminder.id,
            reminder.sentDateTime.toISOString(),
            reminder.confirmedDateTime ? reminder.confirmedDateTime.toISOString() : null
        )
    }

    adaptBookingReminderDto(dto: BookingReminderDTO): BookingReminder {
        return new BookingReminder(
            dto.id,
            new Date(dto.sentDateTime),
            dto.confirmedDateTime ? new Date(dto.confirmedDateTime) : null
        )
    }

    adaptCancellation(cancellation: Cancellation): CancellationDTO {
        return new CancellationDTO(
            cancellation.id,
            cancellation.paymentMethodId,
            cancellation.unitAmount,
            cancellation.currencyCode,
            cancellation.isPerCover,
            cancellation.partySize,
            cancellation.cutOffHours !== null ? cancellation.cutOffHours : null,
            cancellation.chargeAutomatically,
            cancellation.charges.map(charge => this.adaptCancellationCharge(charge)),
            cancellation.dateCreated.toISOString()
        )
    }

    adaptCancellationDto(dto: CancellationDTO): Cancellation {
        return new Cancellation(
            dto.id,
            dto.paymentMethodId,
            dto.unitAmount,
            dto.currencyCode,
            dto.isPerCover,
            dto.partySize,
            dto.cutOffHours !== null ? dto.cutOffHours : null,
            dto.chargeAutomatically,
            dto.charges.map(dto => this.adaptCancellationChargeDto(dto)),
            new Date(dto.dateCreated)
        )
    }

    adaptCancellationCharge(charge: CancellationCharge): CancellationChargeDTO {
        return new CancellationChargeDTO(
            charge.id,
            charge.amount,
            charge.currencyCode,
            charge.partySize,
            charge.paymentIntentId,
            charge.dateCreated.toISOString()
        )
    }

    adaptCancellationChargeDto(dto: CancellationChargeDTO): CancellationCharge {
        return new CancellationCharge(
            dto.id,
            dto.amount,
            dto.currencyCode,
            dto.partySize,
            dto.paymentIntentId,
            new Date(dto.dateCreated)
        )
    }

    adaptDeposit(deposit: Deposit): DepositDTO {
        return new DepositDTO(
            deposit.id,
            deposit.paymentMethodId,
            deposit.amount,
            deposit.currencyCode,
            deposit.dateCreated.toISOString(),
            deposit.datePaid ? deposit.datePaid.toISOString() : null,
            deposit.dateRefunded ? deposit.dateRefunded.toISOString() : null
        )
    }

    adaptDepositDto(dto: DepositDTO): Deposit {
        return new Deposit(
            dto.id,
            dto.paymentMethodId,
            dto.amount,
            dto.currencyCode,
            new Date(dto.dateCreated),
            dto.datePaid ? new Date(dto.datePaid) : null,
            dto.dateRefunded ? new Date(dto.dateRefunded) : null
        )
    }

    adaptUser(user: User): UserDTO {
        return new UserDTO(
            user.id,
            user.name,
            user.emailAddress,
            this.adaptUserRole(user.role),
            user.venueIds ? [...user.venueIds] : null,
            user.notificationPreferences.map(pref => this.adaptUserNotificationPreferences(pref))
        )
    }

    adaptUserRole(role: UserRole): UserRoleDTO {
        switch (role) {
        case UserRole.OWNER:
            return UserRoleDTO.OWNER
        case UserRole.MANAGER:
            return UserRoleDTO.MANAGER
        }
    }

    adaptUserNotificationPreferences(notificationPreferences: UserNotificationPreferences): UserNotificationPreferencesDTO {
        return new UserNotificationPreferencesDTO(
            notificationPreferences.venueId,
            notificationPreferences.bookingRequests
        )
    }

    adaptUserDto(dto: UserDTO): User {
        return new User(
            dto.id,
            dto.name,
            dto.emailAddress,
            this.adaptUserRoleDto(dto.role),
            dto.venueIds ? [...dto.venueIds] : null,
            dto.notificationPreferences.map(dto => this.adaptUserNotificationPreferencesDto(dto))
        )
    }

    adaptUserRoleDto(dto: UserRoleDTO): UserRole {
        switch (dto) {
        case UserRoleDTO.OWNER:
            return UserRole.OWNER
        case UserRoleDTO.MANAGER:
            return UserRole.MANAGER
        }
    }

    adaptUserNotificationPreferencesDto(dto: UserNotificationPreferencesDTO): UserNotificationPreferences {
        return new UserNotificationPreferences(
            dto.venueId,
            dto.bookingRequests
        )
    }

    adaptVenueScheduleDto(dto: VenueScheduleDTO): VenueSchedule {
        const diaryNotes = dto.diaryNotes.map(note => this.adaptDiaryNoteDto(note))
        const areas = dto.areaSchedules.map(dto => this.adaptAreaScheduleDto(dto))
        areas.sort((a, b) => a.area.displayOrder - b.area.displayOrder)
        return new VenueSchedule(
            diaryNotes,
            areas
        )
    }

    adaptDiaryNoteDto(dto: DiaryNoteDTO): DiaryNote {
        return new DiaryNote(
            dto.id,
            DateTime.fromISO(dto.startDate, { zone: environment.assumedVenueTimeZone }).toJSDate(),
            DateTime.fromISO(dto.endDate, { zone: environment.assumedVenueTimeZone }).toJSDate(),
            dto.note
        )
    }

    adaptDiaryNote(note: DiaryNote): DiaryNoteDTO {
        return new DiaryNoteDTO(
            note.id,
            this.adaptToLocalDate(note.startDate),
            this.adaptToLocalDate(note.endDate),
            note.note
        )
    }

    adaptAreaScheduleDto(dto: AreaScheduleDTO): AreaSchedule {
        const area = this.adaptAreaDto(dto.area)
        const closures = dto.closures.map(dto => this.adaptVenueClosureDto(dto))
        return new AreaSchedule(area, closures)
    }

    adaptVenueClosure(venueClosure: VenueClosure): VenueClosureDTO {
        return new VenueClosureDTO(
            venueClosure.id,
            this.adaptToLocalDateTime(venueClosure.start),
            this.adaptToLocalDateTime(venueClosure.end),
            [...venueClosure.tableIds],
            [...venueClosure.reasonIds],
            venueClosure.closes,
            venueClosure.dateCreated.toISOString()
        )
    }

    adaptVenueClosureDto(dto: VenueClosureDTO): VenueClosure {
        return new VenueClosure(
            dto.id,
            DateTime.fromISO(dto.start, { zone: environment.assumedVenueTimeZone }).toJSDate(),
            DateTime.fromISO(dto.end, { zone: environment.assumedVenueTimeZone }).toJSDate(),
            [...dto.tableIds],
            [...dto.reasonIds],
            dto.closes,
            DateTime.fromISO(dto.dateCreated, { zone: environment.assumedVenueTimeZone }).toJSDate()
        )
    }

    adaptSchedule(schedule: Schedule): ScheduleDTO {
        const dateRanges = schedule.dateRanges.map(dateRange => {
            return {
                first: dateRange.start ? this.adaptToLocalDateTime(dateRange.start) : null,
                second: dateRange.end ? this.adaptToLocalDateTime(dateRange.end) : null,
            }
        })
        const durations = schedule.bookingDurations.map(duration => {
            return this.adaptPartySizeDuration(duration)
        })
        const serviceDtos = schedule.services.map(service => {
            return this.adaptService(service)
        })
        const exceptionDtos = schedule.exceptions.map(exception => {
            return this.adaptScheduleException(exception)
        })
        return new ScheduleDTO(
            schedule.id,
            schedule.displayName,
            dateRanges,
            schedule.preBookingWindowMinutes,
            schedule.preBookingModificationWindowMinutes,
            schedule.inAdvanceBookingLimitDays,
            schedule.coverVelocity,
            durations,
            serviceDtos,
            exceptionDtos
        )
    }

    adaptScheduleDto(dto: ScheduleDTO): Schedule {
        const dateRanges = dto.dateRanges.map(dateRange => {
            return {
                start: dateRange.first ? new Date(dateRange.first) : null,
                end: dateRange.second ? new Date(dateRange.second) : null,
            }
        })
        const durations = dto.bookingDurations.map(dto => this.adaptPartySizeDurationDto(dto))
        const services = dto.services.map(dto => this.adaptServiceDto(dto))
        const exceptions = dto.exceptions.map(dto => this.adaptScheduleExceptionDto(dto))
        return new Schedule(
            dto.id,
            dto.displayName,
            dateRanges,
            dto.preBookingWindowMinutes,
            dto.preBookingModificationWindowMinutes,
            dto.inAdvanceBookingLimitDays,
            dto.coverVelocity,
            durations,
            services,
            exceptions
        )
    }

    adaptService(service: Service): ServiceDTO {
        const rules = service.rules.map(rule => this.adaptScheduleRule(rule))
        return new ServiceDTO(
            service.id,
            service.displayName,
            rules
        )
    }

    adaptServiceDto(dto: ServiceDTO): Service {
        const rules = dto.rules.map(dto => this.adaptScheduleRuleDto(dto))
        return new Service(
            dto.id,
            dto.displayName,
            rules
        )
    }

    adaptScheduleRule(rule: ScheduleRule): ScheduleRuleDTO {
        const durations = rule.bookingDurations.map(duration => {
            return this.adaptPartySizeDuration(duration)
        })
        return new ScheduleRuleDTO(
            rule.id,
            rule.dateComponents.map(component => this.adaptDateComponent(component)),
            this.adaptPeriod(rule.period),
            durations,
            rule.paymentsEnabled,
            rule.preBookingWindowMinutes,
            rule.coverLimit,
            rule.manualAcceptance
        )
    }

    adaptScheduleRuleDto(dto: ScheduleRuleDTO): ScheduleRule {
        const durations = dto.bookingDurations.map(dto => this.adaptPartySizeDurationDto(dto))
        return new ScheduleRule(
            dto.id,
            dto.dateComponents.map(dto => this.adaptDateComponentDto(dto)),
            this.adaptPeriodDto(dto.period),
            durations,
            dto.paymentsEnabled,
            dto.preBookingWindowMinutes,
            dto.coverLimit,
            dto.manualAcceptance
        )
    }

    adaptScheduleException(exception: ScheduleException): ScheduleExceptionDTO {
        return new ScheduleExceptionDTO(
            exception.id,
            this.adaptToLocalDate(exception.startDate),
            this.adaptToLocalDate(exception.endDate),
            exception.description
        )
    }

    adaptScheduleExceptionDto(dto: ScheduleExceptionDTO): ScheduleException {
        return new ScheduleException(
            dto.id,
            DateTime.fromISO(dto.startDate, { zone: environment.assumedVenueTimeZone }).toJSDate(),
            DateTime.fromISO(dto.endDate, { zone: environment.assumedVenueTimeZone }).toJSDate(),
            dto.description
        )
    }

    adaptPeriod(period: Period): PeriodDTO {
        return new PeriodDTO(
            period.start.toString(),
            period.end.toString(),
            period.open
        )
    }

    adaptPeriodDto(dto: PeriodDTO): Period {
        return new Period(
            new Time(dto.start, null, null),
            new Time(dto.end, null, null),
            dto.open
        )
    }

    adaptDateComponent(component: DateComponent): DateComponentDTO {
        return new DateComponentDTO(
            component.unit,
            component.value
        )
    }

    adaptDateComponentDto(dto: DateComponentDTO): DateComponent {
        return new DateComponent(
            dto.unit,
            dto.value
        )
    }

    adaptBookingsReportGraphDto(dto: BookingsReportGraphDTO): BookingsReportGraph {
        const date = new Date(dto.bookingsDate)
        date.setHours(0,0,0,0)
        return new BookingsReportGraph(
            date,
            dto.totalBookings
        )
    }

    adaptBookingsReportDataDto(dto: BookingsReportDataDTO): BookingsReportData {
        return new BookingsReportData(
            dto.bookingId,
            this.adaptBookingStatusTypeDto(dto.statusType),
            dto.venueName,
            dto.areaName,
            dto.tableName,
            dto.size,
            new Date(dto.start),
            dto.durationMinutes
        )
    }

    adaptDepositsReportDataDto(dto: DepositsReportDataDTO): DepositsReportData {
        return new DepositsReportData(
            dto.bookingId,
            new Date(dto.start),
            DineroFactory({
                amount: dto.amount,
                currency: dto.currencyCode as DineroFactory.Currency,
            }),
            dto.partySize,
            this.adaptContactDto(dto.contact),
            new Date(dto.datePaid),
            dto.dateRefunded ? new Date(dto.dateRefunded) : null
        )
    }

    adaptDepositsReportGraphDto(dto: DepositsReportGraphDTO): DepositsReportGraph {
        return new DepositsReportGraph(
            new Date(dto.date),
            dto.grossCount,
            dto.netCount,
            dto.grossAmount,
            dto.netAmount,
            dto.currencyCode
        )
    }

    adaptBillingDetails(billingDetails: BillingDetails): BillingDetailsDTO {
        return new BillingDetailsDTO(
            billingDetails.inService,
            billingDetails.needsToUpgradeTrial,
            billingDetails.nextBillingAmount,
            billingDetails.nextBillingAmountCurrencyCode,
            billingDetails.nextBillingDate,
            [...billingDetails.addons]
        )
    }

    adaptBillingDetailsDto(dto: BillingDetailsDTO): BillingDetails {
        const nextBillingDate = dto.nextBillingDate ? new Date(dto.nextBillingDate) : null
        return new BillingDetails(
            dto.inService,
            dto.needsToUpgradeTrial,
            dto.nextBillingAmount,
            dto.nextBillingAmountCurrencyCode,
            nextBillingDate,
            [...dto.addons]
        )
    }

    adaptPaymentsAccount(paymentsAccount: PaymentsAccount): PaymentsAccountDTO {
        return new PaymentsAccountDTO(
            paymentsAccount.id,
            paymentsAccount.depositApplicationFeePercent,
            paymentsAccount.gateway,
            paymentsAccount.apiKey ? paymentsAccount.apiKey : null
        )
    }

    adaptPaymentsAccountDto(dto: PaymentsAccountDTO): PaymentsAccount {
        return new PaymentsAccount(
            dto.id,
            dto.depositApplicationFeePercent,
            dto.gateway,
            dto.apiKey ? dto.apiKey : null,
        )
    }

    adaptPaymentsAccountDetailsDto(dto: PaymentsAccountDetailsDTO): PaymentsAccountDetails {
        return new PaymentsAccountDetails(
            dto.chargesEnabled
        )
    }

    adaptPaymentsAccountDetails(
        paymentsAccountDetails: PaymentsAccountDetails
    ): PaymentsAccountDetailsDTO {
        return new PaymentsAccountDetailsDTO(
            paymentsAccountDetails.chargesEnabled
        )
    }

    private adaptToLocalDateTime(date: Date): string {
        return DateTime.fromJSDate(date, { zone: environment.assumedVenueTimeZone })
            .toISO({
                includeOffset: false,
                includePrefix: true,
            })!
    }

    private adaptToLocalDate(date: Date): string {
        return DateTime.fromJSDate(date, { zone: environment.assumedVenueTimeZone })
            .toISODate()!
    }

    adaptBookingsFeedbackReportDataDto(
        dto: BookingsFeedbackReportDataDTO
    ): BookingsFeedbackReportData {
        return new BookingsFeedbackReportData(
            dto.firstName,
            dto.lastName,
            dto.emailAddress,
            new Date(dto.startTime),
            dto.rating,
            dto.food,
            dto.service,
            dto.atmosphere,
            dto.comment,
            dto.isRedirectedExternally,
            new Date(dto.feedbackDateCreated)
        )
    }

    adaptCustomerListExportDto(
        dto: CustomerListExportDataDTO
    ): CustomerListExportData {
        const contact = new Contact(
            dto.firstName,
            dto.lastName,
            dto.emailAddress,
            dto.phoneNumber
        )
        return new CustomerListExportData(
            dto.bookingId,
            contact,
            dto.bookingSize,
            dto.bookingStartTime,
            dto.agreedToMarketing,
            this.adaptBookingSourceDto(dto.source)
        )
    }

    adaptBookingsPerHourReportDataDto(
        dto: BookingsPerHourReportDataDTO
    ): BookingsPerHourReportData {
        return new BookingsPerHourReportData(
            dto.bookingHour,
            dto.customerMinBookings,
            dto.customerAvgBookings,
            dto.customerMaxBookings,
            dto.merchantMinBookings,
            dto.merchantAvgBookings,
            dto.merchantMaxBookings
        )
    }

    adaptCreateUserRequest(createUserRequest: CreateUserRequest): CreateUserRequestDTO {
        return new CreateUserRequestDTO(
            createUserRequest.name,
            createUserRequest.emailAddress
        )
    }

    adaptCreateUserRequestDto(dto: CreateUserRequestDTO): CreateUserRequest {
        return new CreateUserRequest(
            dto.name,
            dto.emailAddress
        )
    }

    adaptEmailMarketingConfiguration(
        em: EmailMarketingConfiguration
    ): EmailMarketingConfigurationDTO {
        return new EmailMarketingConfigurationDTO(
            em.id,
            em.isEnabled,
            this.adaptEmailMarketingSource(em.source),
            em.apiKey,
            em.list,
            em.fields.map(field => this.adaptEmailMarketingField(field)),
            this.adaptEmailMarketingTagsList(em.tags),
            this.adaptEmailMarketingStatus(em.status)
        )
    }

    adaptMoveBookingRequest(
        booking: Booking,
        tables: Table[],
        start?: Date
    ): MoveBookingsRequestDTO {
        return new MoveBookingsRequestDTO(
            booking.id,
            tables.map(table => table.id),
            start ? this.adaptToLocalDateTime(start) : null
        )
    }

    adaptBookingAuditEntry(entry: BookingAuditEntry): BookingAuditEntryDTO {
        return new BookingAuditEntryDTO(
            entry.id,
            entry.changes,
            entry.bookingId,
            entry.userId,
            entry.dateTime.toISOString()
        )
    }

    adaptBookingAuditEntryDto(dto: BookingAuditEntryDTO): BookingAuditEntry {
        return new BookingAuditEntry(
            dto.id,
            dto.changes,
            dto.bookingId,
            dto.userId,
            new Date(dto.dateTime)
        )
    }

    adaptBookingRequest(bookingRequest: BookingRequest): BookingRequestDTO {
        return new BookingRequestDTO(
            new BookingSlotDTO(bookingRequest.bookingSlot.dateTime),
            this.adaptContact(bookingRequest.contact),
            bookingRequest.partySize,
            bookingRequest.notes,
            bookingRequest.agreedToMarketing,
            bookingRequest.agreedToTerms,
            bookingRequest.paymentMethodId,
            bookingRequest.reasonId,
            bookingRequest.eventId,
            bookingRequest.answers.map(answer => this.adaptAnswer(answer)),
            bookingRequest.depositPaymentIntentId,
            bookingRequest.requiresWheelchairAccess,
            bookingRequest.requiresDogFriendly,
            bookingRequest.dateCreated.toISOString()
        )
    }

    adaptBookingRequestDto(dto: BookingRequestDTO): BookingRequest {
        return new BookingRequest(
            new BookingSlot(new Date(dto.bookingSlot.dateTime)),
            this.adaptContactDto(dto.contact),
            dto.partySize,
            dto.notes,
            dto.agreedToMarketing,
            dto.agreedToTerms,
            dto.paymentMethodId,
            dto.reasonId,
            dto.eventId,
            dto.answers.map(dto => this.adaptAnswerDto(dto)),
            dto.depositPaymentIntentId,
            dto.requiresWheelchairAccess,
            dto.requiresDogFriendly,
            new Date(dto.dateCreated)
        )
    }


    adaptProductLibrary(productLibrary: ProductLibrary): ProductLibraryDTO {
        return new ProductLibraryDTO(
            productLibrary.id,
            productLibrary.venueId,
            productLibrary.vouchers.map(voucher => this.adaptVoucher(voucher))
        )
    }

    adaptProductLibraryDto(dto: ProductLibraryDTO): ProductLibrary {
        return new ProductLibrary(
            dto.id,
            dto.venueId,
            dto.vouchers.map(dto => this.adaptVoucherDto(dto))
        )
    }

    adaptVoucher(voucher: Voucher): VoucherDTO {
        return new VoucherDTO(
            voucher.id,
            voucher.displayName,
            voucher.description,
            voucher.policy,
            voucher.expiryMonths ? voucher.expiryMonths : null,
            voucher.skus.map(sku => this.adaptSku(sku)),
            this.adaptToLocalDateTime(voucher.dateCreated),
            this.adaptToLocalDateTime(voucher.dateUpdated)
        )
    }

    adaptVoucherDto(dto: VoucherDTO): Voucher {
        return new Voucher(
            dto.id,
            dto.displayName,
            dto.description,
            dto.policy,
            dto.expiryMonths ? dto.expiryMonths : null,
            dto.skus.map(dto => this.adaptSkuDto(dto)),
            new Date(dto.dateCreated),
            new Date(dto.dateUpdated)
        )
    }

    adaptSku(sku: SKU): SKUDTO {
        return new SKUDTO(
            sku.id,
            sku.price,
            sku.onSale,
            sku.dateCreated.toISOString(),
            sku.dateUpdated.toISOString()
        )
    }

    adaptSkuDto(dto: SKUDTO): SKU {
        return new SKU(
            dto.id,
            dto.price,
            dto.onSale,
            new Date(dto.dateCreated),
            new Date(dto.dateUpdated)
        )
    }

    adaptGiftedVoucher(giftedVoucher: GiftedVoucher): GiftedVoucherDTO {
        return new GiftedVoucherDTO(
            giftedVoucher.id,
            giftedVoucher.venueId,
            giftedVoucher.lineItemId,
            giftedVoucher.amount,
            giftedVoucher.currencyCode,
            giftedVoucher.redeemed,
            giftedVoucher.expiryDate ? this.adaptToLocalDate(giftedVoucher.expiryDate) : null,
            giftedVoucher.recipientName ? giftedVoucher.recipientName : null,
            this.adaptToLocalDate(giftedVoucher.dateCreated),
            this.adaptToLocalDate(giftedVoucher.dateUpdated)
        )
    }

    adaptGiftedVoucherDto(dto: GiftedVoucherDTO): GiftedVoucher {
        return new GiftedVoucher(
            dto.id,
            dto.venueId,
            dto.lineItemId,
            dto.amount,
            dto.currencyCode,
            dto.redeemed,
            dto.expiryDate
                ? DateTime.fromISO(dto.expiryDate, { zone: environment.assumedVenueTimeZone }).toJSDate()
                : null,
            dto.recipientName ? dto.recipientName : null,
            DateTime.fromISO(dto.dateCreated, { zone: environment.assumedVenueTimeZone }).toJSDate(),
            DateTime.fromISO(dto.dateUpdated, { zone: environment.assumedVenueTimeZone }).toJSDate()
        )
    }

    adaptGiftedVoucherAuditEntryDto(dto: GiftedVoucherAuditEntryDTO): GiftedVoucherAuditEntry {
        return new GiftedVoucherAuditEntry(
            dto.id,
            dto.changes,
            dto.giftedVoucherId,
            dto.userId ? dto.userId : null,
            new Date(dto.dateTime)
        )
    }

    private adaptEmailMarketingSource(source: EmailMarketingSource): EmailMarketingSourceDTO {
        switch (source) {
        case EmailMarketingSource.EMAIL_OCTOPUS:
            return EmailMarketingSourceDTO.EMAIL_OCTOPUS
        }
    }

    private adaptEmailMarketingField(field: EmailMarketingListField): EmailMarketingListFieldDTO {
        return new EmailMarketingListFieldDTO(
            this.adaptEmailMarketingListAssociationType(field.association),
            field.tag,
            field.type,
            field.label,
            field.fallback
        )
    }

    private adaptEmailMarketingListAssociationType(type: EmailMarketingListAssociationType):
        EmailMarketingListAssociationTypeDTO {
        switch (type) {
        case EmailMarketingListAssociationType.FIRSTNAME:
            return EmailMarketingListAssociationTypeDTO.FIRSTNAME
        case EmailMarketingListAssociationType.LASTNAME:
            return EmailMarketingListAssociationTypeDTO.LASTNAME
        case EmailMarketingListAssociationType.EMAIL_ADDRESS:
            return EmailMarketingListAssociationTypeDTO.EMAIL_ADDRESS
        case EmailMarketingListAssociationType.PHONE_NUMBER:
            return EmailMarketingListAssociationTypeDTO.PHONE_NUMBER
        }
    }

    private adaptEmailMarketingTagsList(tags: EmailMarketingTag[] | null):
        EmailMarketingTagDTO[] | null {
        if (tags === null) { return null }
        return tags.map(tag => this.adaptEmailMarketingTag(tag))
    }
    private adaptEmailMarketingTag(tag: EmailMarketingTag): EmailMarketingTagDTO {
        return new EmailMarketingTagDTO(
            tag.name
        )
    }

    private adaptEmailMarketingStatus(status: EmailMarketingStatus): EmailMarketingStatusDTO {
        switch (status) {
        case EmailMarketingStatus.PENDING:
            return EmailMarketingStatusDTO.PENDING
        case EmailMarketingStatus.SUBSCRIBED:
            return EmailMarketingStatusDTO.SUBSCRIBED
        case EmailMarketingStatus.UNSUBSCRIBED:
            return EmailMarketingStatusDTO.UNSUBSCRIBED
        }
    }

    adaptEmailMarketingConfigurationDto(dto: EmailMarketingConfigurationDTO):
        EmailMarketingConfiguration {
        return new EmailMarketingConfiguration(
            dto.id,
            dto.isEnabled,
            this.adaptEmailMarketingSourceDto(dto.source),
            dto.apiKey,
            this.adaptEmailMarketingListDto(dto.list),
            dto.fields.map(field => this.adaptEmailMarketingFieldDto(field)),
            this.adaptEmailMarketingTagsListDto(dto.tags),
            this.adaptEmailMarketingStatusDto(dto.status)
        )
    }

    adaptCustomer(customer: Customer): CustomerDTO {
        let notes: string[] = []
        if (customer.notes !== null) {
            notes = [...customer.notes]
        }
        return new CustomerDTO(
            customer.id,
            customer.firstName,
            customer.lastName,
            customer.emailAddress,
            customer.phoneNumber,
            notes,
            customer.venueId
        )
    }

    adaptCustomerDto(dto: CustomerDTO): Customer {
        let notes: string[] = []
        if (dto.notes !== null) {
            notes = [...dto.notes]
        }
        return new Customer(
            dto.id,
            dto.firstName,
            dto.lastName,
            dto.emailAddress,
            dto.phoneNumber,
            notes,
            dto.venueId
        )
    }

    adaptSenderId(senderId: SenderId): SenderIdDTO {
        return new SenderIdDTO(
            senderId.originationId,
            senderId.countryCode
        )
    }

    adaptSenderIdDto(dto: SenderIdDTO): SenderId {
        return new SenderId(
            dto.originationId,
            dto.countryCode
        )
    }

    adaptSaleDto(dto: SaleDTO): Sale {
        return new Sale(
            dto.id,
            dto.venueId,
            this.adaptOrderDto(dto.order),
            dto.payment ? this.adaptPaymentDto(dto.payment) : null,
            dto.deliveryDetails ? this.adaptDeliveryDetailsDto(dto.deliveryDetails) : null,
            new Date(dto.dateCreated),
            new Date(dto.dateUpdated)
        )
    }

    adaptPaymentDto(dto: PaymentDTO): Payment {
        return new Payment(
            dto.id,
            dto.paymentIntentId,
            new Date(dto.dateCreated),
            new Date(dto.datePaid)
        )
    }

    adaptDeliveryDetailsDto(dto: DeliveryDetailsDTO): DeliveryDetails {
        return new DeliveryDetails(
            dto.emailAddress
        )
    }

    adaptOrderDto(dto: OrderDTO): Order {
        return new Order(
            dto.id,
            dto.venueId,
            dto.lineItems.map(lineItem => this.adaptLineItemDto(lineItem)),
            dto.currencyCode,
            new Date(dto.dateCreated),
            new Date(dto.dateUpdated)
        )
    }

    adaptLineItemDto(dto: LineItemDTO): LineItem {
        return new LineItem(
            dto.id,
            dto.productId,
            dto.skuId,
            dto.name,
            dto.amount,
            dto.quantity,
            DateTime.fromISO(dto.dateCreated, { zone: environment.assumedVenueTimeZone }).toJSDate(),
            DateTime.fromISO(dto.dateUpdated, { zone: environment.assumedVenueTimeZone }).toJSDate()
        )
    }

    private adaptEmailMarketingListDto(dto: EmailMarketingListDTO): EmailMarketingList {
        return new EmailMarketingList(
            dto.listId,
            dto.displayName
        )
    }

    private adaptEmailMarketingSourceDto(dto: EmailMarketingSourceDTO): EmailMarketingSource {
        switch (dto) {
        case EmailMarketingSourceDTO.EMAIL_OCTOPUS:
            return EmailMarketingSource.EMAIL_OCTOPUS
        }
    }

    private adaptEmailMarketingFieldDto(dto: EmailMarketingListFieldDTO): EmailMarketingListField {
        return new EmailMarketingListField(
            this.adaptEmailMarketingListAssociationTypeDto(dto.association),
            dto.tag,
            dto.type,
            dto.label,
            dto.fallback
        )
    }

    private adaptEmailMarketingListAssociationTypeDto(dto: EmailMarketingListAssociationTypeDTO):
        EmailMarketingListAssociationType {
        switch (dto) {
        case EmailMarketingListAssociationTypeDTO.FIRSTNAME:
            return EmailMarketingListAssociationType.FIRSTNAME
        case EmailMarketingListAssociationTypeDTO.LASTNAME:
            return EmailMarketingListAssociationType.LASTNAME
        case EmailMarketingListAssociationTypeDTO.EMAIL_ADDRESS:
            return EmailMarketingListAssociationType.EMAIL_ADDRESS
        case EmailMarketingListAssociationTypeDTO.PHONE_NUMBER:
            return EmailMarketingListAssociationType.PHONE_NUMBER
        }
    }

    private adaptEmailMarketingTagsListDto(dto: EmailMarketingTagDTO[] | null):
        EmailMarketingTag[] | null {
        if (dto === null) { return null }
        return dto.map(dto => this.adaptEmailMarketingTagDto(dto))
    }

    private adaptEmailMarketingTagDto(dto: EmailMarketingTagDTO): EmailMarketingTag {
        return new EmailMarketingTag(
            dto.name
        )
    }

    private adaptEmailMarketingStatusDto(dto: EmailMarketingStatusDTO): EmailMarketingStatus {
        switch (dto) {
        case EmailMarketingStatusDTO.PENDING:
            return EmailMarketingStatus.PENDING
        case EmailMarketingStatusDTO.SUBSCRIBED:
            return EmailMarketingStatus.SUBSCRIBED
        case EmailMarketingStatusDTO.UNSUBSCRIBED:
            return EmailMarketingStatus.UNSUBSCRIBED
        }
    }
}

export type PaymentGateway = 'stripe' | 'breathepay';
export class PaymentsAccount {
    constructor(
        public id: string,
        public depositApplicationFeePercent: number,
        public gateway: PaymentGateway,
        public apiKey: string | null
    ) { }

    get depositApplicationFeePercentage(): number {
        return this.depositApplicationFeePercent / 100
    }

    get cancellationChargeApplicationFeePercentage(): number {
        return 0
    }

    usesStripe(): boolean {
        return this.gateway === 'stripe';
    }

    usesBreathe(): boolean {
        return this.gateway === 'breathepay';
    }
}
export class GiftedVoucherDTO {
    constructor(
        public id: string,
        public venueId: string,
        public lineItemId: string,
        public amount: number,
        public currencyCode: string,
        public redeemed: boolean,
        public expiryDate: string | null,
        public recipientName: string | null,
        public dateCreated: string,
        public dateUpdated: string
    ) { }
}
